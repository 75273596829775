/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-02-08",
    versionChannel: "nightly",
    buildDate: "2025-02-08T00:06:00.238Z",
    commitHash: "158ff2122a72a2d20b51c1afb9c4d096a1dbee97",
};
